import { Flex, Link } from '@stars-ecom/shared-atoms-ui'
import take from 'lodash/take'
import React, { memo } from 'react'

import { DataLayerUtils } from '../context'
import slugify from '../utils/slugify'
import BlogArticleThumbnail from './BlogArticleThumbnail'
import BlogCategoryHeader from './BlogCategoryHeader'

const BlogCategorySection = (props) => {
  const { blogCategory } = props
  return (
    <BlogCategoryHeader blogCategory={blogCategory} useBackgroundImage={true}>
      <Flex flexDirection="row" flexWrap="wrap" justify="space-evenly" gridGap="10px">
        {take(blogCategory?.blog_articles, 6).map((blogArticle, index) => (
          <BlogArticleThumbnail
            blogArticle={blogArticle}
            key={`cat_${blogCategory.id}_art_${index}`}
          />
        ))}
        <Flex
          width="100%"
          justify="flex-end"
          fontFamily="PT Sans Narrow, PT Sans, Arial, sans-serif"
          fontWeight={400}
          fontSize="15px">
          <Link
            underlineOnHover
            to={`/blog/${slugify(blogCategory?.title)}`}
            onClick={() =>
              DataLayerUtils.addEvent({
                event: 'qwampEventLab',
                eventCat: 'Blog',
                eventAct: "Affichage plus d'articles",
                eventLab: blogCategory?.title
              })
            }>
            &gt; Voir plus d&acute;articles
          </Link>
        </Flex>
      </Flex>
    </BlogCategoryHeader>
  )
}

export default memo(BlogCategorySection)
