import { BackgroundImage, Flex, Text } from '@stars-ecom/shared-atoms-ui'
import React, { memo } from 'react'

const BlogCategoryHeader = (props) => {
  const { blogCategory, children, useBackgroundImage } = props

  return (
    <BackgroundImage
      backgroundImage={useBackgroundImage ? blogCategory?.backgroundImage?.image : null}
      backgroundColor={blogCategory?.backgroundColor}
      anchor={`section_${blogCategory?.id}`}
      height={{ base: 'auto', md: '920px' }}
      scrollMarginTop={{ base: '100px', md: '50px' }}
      style={{
        backgroundPosition: 'center top',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'initial',
        textAlign: 'center',
        paddingTop: '0px',
        paddingBottom: '20px',
        transition: 'all .4s',
        zIndex: '0'
      }}>
      <Flex
        flexDirection="column"
        w={{ base: 'auto', md: '1000px' }}
        pt="32px"
        pl={{ base: '10px', md: '0' }}
        pr={{ base: '10px', md: '0' }}
        m="0 auto"
        style={{}}>
        <Text
          as="h2"
          textAlign="center"
          fontSize={{ base: '8vw', md: '27px', lg: '38px' }}
          fontWeight={400}
          style={{ fontFamily: 'Courgette,cursive' }}>
          {blogCategory?.title}
        </Text>
        <Text
          dangerouslySetInnerHTML={{ __html: blogCategory?.description }}
          fontSize={{ base: '3vw', md: '9px', lg: '13px' }}
          lineHeight={{ base: '4vw', md: '11px', lg: '16px' }}
          pt={{ base: '16px', md: '13px', lg: '16px' }}
          pb={{ base: '16px', md: '13px', lg: '16px' }}
          pl="10px"
          pr="10px"
          textAlign="center"
          fontFamily="Arial, sans-serif"></Text>
        {children}
      </Flex>
    </BackgroundImage>
  )
}

export default memo(BlogCategoryHeader)
