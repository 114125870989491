import './blog-article.css'

import { Box, Flex, Media, rewriteMediaUrl, Text } from '@stars-ecom/shared-atoms-ui'
import React, { memo, useContext, useEffect, useState } from 'react'

import { WebsiteContext } from '../context'
import JsonLd from '../utils/jsonLd'
import BlogProducts from './BlogProducts'
const BlogArticle = (props) => {
  const { blogArticle, products } = props
  const websiteContext = useContext(WebsiteContext)
  const [jsonLdData, setJsonLdData] = useState({})
  const contentWithMediaUrls = blogArticle ? rewriteMediaUrl(blogArticle.content) : ''

  useEffect(() => {
    buildJsonLd()
  }, [blogArticle])

  const location = typeof window !== 'undefined' ? window.location : ''

  const buildJsonLd = () => {
    if (typeof window !== 'undefined') {
      const wrapper = {
        '@context': 'https://schema.org',
        '@type': 'BlogPosting',
        headline: blogArticle?.title,
        description: blogArticle?.hook,
        articleSection: blogArticle?.blog_taxonomy?.title,
        image: blogArticle?.media?.url,
        datePublished: blogArticle?.startDate,
        url: location.href, //location undefined,
        publisher: {
          '@type': 'Organization',
          name: websiteContext.title,
          logo: {
            '@type': 'ImageObject',
            '@id': websiteContext?.logo?.url,
            url: websiteContext?.logo?.url,
            width: websiteContext?.logo?.image?.childImageSharp.gatsbyImageData.width,
            height: websiteContext?.logo?.image?.childImageSharp.gatsbyImageData.height
          }
        },
        isPartOf: {
          '@type': 'Blog',
          '@id': location.origin + '/blog/', //https://teleshopping-fr.dev.ecom-stars.com/article/undefined/blog/
          name: websiteContext.title + ' Blog',
          publisher: {
            '@type': 'Organization',
            '@id': location.origin, // ne remonte pas
            name: websiteContext.title
          }
        }
      }
      setJsonLdData(wrapper)
    }
  }

  return (
    <Flex
      direction={{ base: 'column' }}
      maxWidth={{ base: 'full', md: '1000px' }}
      m={{ base: '44px 0 50px 0', md: '44px auto 50px auto' }}
      gridGap="30px"
      background>
      {typeof jsonLdData['@type'] != 'undefined' && <JsonLd jsonLdData={jsonLdData} />}
      <Text
        as="h1"
        fontSize={{ base: '12px', md: '38px' }}
        lineHeight={{ base: '9vw', md: '41px', lg: '46px' }}
        fontFamily="Courgette, Arial, sans-serif"
        fontWeight={400}
        m={{ base: '0 16px', md: '0' }}
        p={{ base: '0 7vw', md: '0' }}
        className="blog-article-title">
        {blogArticle?.title}
      </Text>
      <Box
        as="h3"
        lineHeight={{ base: '16px', md: '18px' }}
        m={{ base: '0 16px', md: '0' }}
        fontFamily="Arial, Helvetica, sans-serif"
        textAlign="justify"
        fontSize={{ base: '12px', md: '15px' }}
        dangerouslySetInnerHTML={{ __html: blogArticle?.subTitle }}></Box>
      <Media
        asset={blogArticle?.media}
        defaultImage={blogArticle?.thumbnailImage}
        loading="lazy"
        alt={blogArticle?.title}
        overlayColor={websiteContext.mainColor}
        zoomable={false}
        style={{
          cursor: 'default'
        }}
        className="blog-article-media"
      />
      {contentWithMediaUrls && (
        <Box
          className="blog-article"
          textAlign="justify"
          dangerouslySetInnerHTML={{ __html: contentWithMediaUrls }}></Box>
      )}
      {products?.length > 0 && <BlogProducts products={products} />}
    </Flex>
  )
}

export default memo(BlogArticle)
