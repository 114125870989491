import {
  BackgroundImage,
  Box,
  Flex,
  ListItem,
  Text,
  UnorderedList
} from '@stars-ecom/shared-atoms-ui'
import React, { useContext, useEffect, useState } from 'react'

import { DataLayerUtils, WebsiteContext } from '../context'

const BlogHeader = (props) => {
  const { blog, fixedThreshold = 440 } = props
  const websiteContext = useContext(WebsiteContext)
  const windowGlobal = typeof window !== 'undefined' && window
  const [headerFixed, setHeaderFixed] = useState(false)

  const toggleHeaderFixed = () => {
    const scrollTop = document?.body?.scrollTop || document?.documentElement?.scrollTop
    setHeaderFixed(scrollTop > fixedThreshold)
  }

  useEffect(() => {
    windowGlobal?.addEventListener('scroll', toggleHeaderFixed, { passive: true })
    return () => {
      windowGlobal?.removeEventListener('scroll', toggleHeaderFixed, { passive: true })
    }
  }, [])

  const goToAnchorBlog = (id, title) => {
    DataLayerUtils.addEvent({
      event: 'qwampEventLab',
      eventCat: 'Blog',
      eventAct: "Départ catégorie d'articles",
      eventLab: title
    })
    let top = document.getElementById(id)?.offsetTop || 0
    top = websiteContext?.isMobile ? top : top + 140
    windowGlobal?.scrollTo({ top, behavior: 'smooth' })
  }

  return (
    <Flex width="100%" height={{ base: '83vw', md: '266px', lg: '379px' }} overflow="hidden">
      <BackgroundImage
        backgroundImage={blog?.backgroundImage?.image}
        backgroundColor={blog?.backgroundColor}
        style={{
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          textAlign: 'center',
          padding: 0,
          transition: 'all .4s',
          height: '100%',
          width: '100%'
        }}>
        <Text
          as="h1"
          textAlign="center"
          color="#fff"
          pt={{ base: '22.5vw', md: '78px', lg: '112px' }}
          fontSize={{ base: '12vw', md: '59px', lg: '84px' }}
          fontWeight={400}
          fontFamily="Courgette, Cursive"
          lineHeight={{ base: '13.5vw', md: '71px', lg: '100px' }}>
          {blog?.title}
        </Text>
        <Text
          as="h2"
          textAlign="center"
          color="#fff"
          fontFamily="PT Sans Narrow, PT Sans, Arial; sans-serif"
          fontWeight={400}
          fontSize={{ base: '5vw', md: '18px', lg: '25px' }}
          lineHeight={{ base: '7vw', md: '21px', lg: '30px' }}>
          {blog?.subTitle}
        </Text>
        <Box
          mt={{
            base: '7.2%',
            md: headerFixed ? '72px' : '32px',
            lg: headerFixed ? '72px' : '46px'
          }}
          transition="background-color .4s"
          bg={{ base: 'transparent', md: headerFixed ? 'rgba(255,255,255,.9)' : 'transparent' }}>
          <Box
            className="navigator"
            zIndex="1010"
            w={headerFixed ? '100%' : 'auto'}
            bg={headerFixed ? 'rgba(255,255,255,.9)' : 'transparent'}
            position={headerFixed ? 'fixed' : 'initial'}
            display={headerFixed ? 'flex' : 'inline-block'}
            flexDirection={headerFixed ? 'row' : 'initial'}
            alignItems={headerFixed ? 'center' : 'initial'}
            top={headerFixed ? '0' : 'auto'}
            transition="background-color .4s"
            boxShadow="lg"
            maxWidth={headerFixed ? '100%' : '561px'}
            width={headerFixed ? '100%' : '95%'}>
            <UnorderedList
              color="#000"
              bg={headerFixed ? 'transparent' : 'rgba(255,255,255,.9)'}
              listStyleType="none"
              display="flex"
              width="100%"
              maxW="565px"
              m={['auto', 'auto', 'auto', 'auto']}
              borderRadius={{ base: '8px', md: '10px' }}>
              {blog?.blog_categories.map(({ blog_category }, i) => (
                <ListItem
                  key={`category_${blog_category?.id}`}
                  className="navigatorItem"
                  fontSize={{ base: '4vw', md: '14px', lg: '20px' }}
                  fontFamily="PT Sans Narrow, PT Sans, Arial, sans-serif"
                  lineHeight={{ base: '3vw', md: '16px', lg: '24px' }}
                  color="#333"
                  flexGrow="1"
                  pt={{ base: '3.6vw', md: '10px', lg: '14px' }}
                  pb={{ base: '3.6vw', md: '10px', lg: '14px' }}
                  //flex={['1 1 auto', '1 1 auto', 'initial', 'initial']}
                  width={{ base: '33%', md: headerFixed ? '33%' : '187px' }}
                  textTransform="uppercase"
                  borderLeft={i == 0 ? 'none' : '1px solid #000'}>
                  <Box
                    width="100%"
                    sx={{
                      '.navigatorItem:hover &': {
                        textDecoration: 'underline',
                        cursor: 'pointer'
                      }
                    }}>
                    <Box
                      onClick={() =>
                        goToAnchorBlog(`section_${blog_category?.id}`, blog_category?.title)
                      }>
                      {blog_category?.title}
                    </Box>
                  </Box>
                </ListItem>
              ))}
            </UnorderedList>
          </Box>
        </Box>
      </BackgroundImage>
    </Flex>
  )
}

export default BlogHeader
