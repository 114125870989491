import './blog-article.css'

import {
  Box,
  Carousel,
  Flex,
  Link,
  ListItem,
  Media,
  Picto,
  Text,
  UnorderedList,
  useBreakpointValue
} from '@stars-ecom/shared-atoms-ui'
import { navigate } from 'gatsby'
import first from 'lodash/first'
import React, { memo, useContext, useEffect, useState } from 'react'

import { WebsiteContext } from '../context/WebsiteContext'
import { ProductBlogThumbnail } from '../product'

const Dot = (props) => {
  const { onClick, active } = props
  const websiteContext = useContext(WebsiteContext)

  return (
    <div
      style={{
        width: '14px',
        height: '14px',
        borderRadius: '7px',
        backgroundColor: active ? websiteContext?.mainColor : 'white',
        border: '1px solid #9c9c9c',
        cursor: active ? 'default' : 'pointer'
      }}
      onClick={active ? null : onClick}
    />
  )
}

const PrevArrow = (props) => {
  const { onClick } = props
  return (
    <Picto
      icon="chevronLeft"
      width="14px"
      onClick={onClick}
      color="#000000"
      style={{ marginRight: '10px', cursor: 'pointer' }}
    />
  )
}

const NextArrow = (props) => {
  const { onClick } = props
  return (
    <Picto
      icon="chevronRight"
      width="14px"
      onClick={onClick}
      color="#000000"
      style={{ marginLeft: '10px', cursor: 'pointer' }}
    />
  )
}

const BlogProducts = (props) => {
  const { products } = props
  const websiteContext = useContext(WebsiteContext)
  const singleProduct = products?.length == 1
  const onlyTwoProducts = products?.length == 2
  const responsiveSlidesToShow = useBreakpointValue({ base: 1, md: 2 })

  const windowGlobal = typeof window !== 'undefined' && window

  const [screenWidth, setWidthState] = useState(windowGlobal?.innerWidth)

  const setWidth = () => {
    setWidthState(windowGlobal?.innerWidth)
  }

  useEffect(() => {
    windowGlobal?.addEventListener('resize', setWidth)

    return () => {
      windowGlobal?.removeEventListener('resize', setWidth)
    }
  }, [screenWidth])

  const cleanProductsList = (products) => {
    let product = []
    products.map((p) => {
      if (p.variants?.[0].customFields.productType !== 'FID' && p.variants?.[0].customFields.productType !== 'EXT') {
        product.push(p)
      }
    })
    return product
  }
  const cleanProducts = cleanProductsList(products)

  return (
    <Flex
      direction="column"
      align="center"
      maxW={{ base: 'full', md: '1000px' }}
      m={{ base: '10px 0', md: '10px auto' }}
      style={{ backgroundColor: '#FFFFFF', borderTop: '1px solid #f6f3f1' }}>
      <Text
        as="h2"
        fontFamily="Courgette, Arial, sans-serif"
        color="#333333"
        fontWeight={400}
        pt={{ base: '8vw', md: '34px', lg: '38px' }}
        fontSize={{ base: '8vw', md: '34px', lg: '38px' }}
        lineHeight={{ base: '9vw', md: '41px', lg: '46px' }}
        textAlign="center"
        width="100%"
        className="blog-associated-products">
        {singleProduct ? 'Le produit associé' : 'Les produits associés'}
      </Text>
      {singleProduct ? (
        <Flex
          flexDirection={{ base: 'column', md: 'row' }}
          width={{ base: '100%', md: '700px' }}
          justify="space-between"
          align={{ base: 'center', md: 'flex-start' }}
          style={{
            padding: '10px',
            backgroundColor: '#ffffff',
            overflow: 'hidden'
          }}>
          <Flex
            style={{
              border: '1px solid #e7e7e7',
              width: '310px',
              height: '250px',
              padding: '10px'
            }}>
            <div style={{ width: '280px', height: '230px' }}>
              {first(products).featuredAsset && (
                <Link to={`/${first(products)?.customFields?.urlKey}`}>
                  <Media
                    alt={first(products)?.name}
                    asset={first(products)?.featuredAsset}
                    style={{ width: '280px', height: '230px' }}
                  />
                </Link>
              )}
            </div>
          </Flex>
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            w={{ base: '100%', md: '300px' }}
            maxWidth="300px"
            h="100%"
            mb={{ base: '0px', md: 'unset' }}
            mt={{ base: '20px', md: '0px' }}>
            <Text
              as="h2"
              style={{
                fontSize: '24px',
                fontWeight: 700,
                fontFamily: websiteContext.fontFamily,
                textAlign: 'left'
              }}>
              {first(products)?.name}
            </Text>
            <UnorderedList
              listStyleType="none"
              textAlign="left"
              width="100%"
              style={{ marginInlineStart: '0px' }}>
              {first(products).customFields?.argContext?.map((arg, i) => (
                <ListItem
                  key={`p_${i}`}
                  p="5px 5px 5px 0"
                  fontFamily={websiteContext.fontFamily}
                  color={'#333333'}
                  fontSize={{ base: '14px', md: '18px' }}
                  _before={{ content: "'. '", fontWeight: '700', color: '#f34343' }}>
                  {arg}
                </ListItem>
              ))}
            </UnorderedList>
            <Box
              style={{ width: '100%' }}
              onClick={() => navigate(`/${first(products)?.customFields?.urlKey}`)}>
              <Flex
                backgroundColor={websiteContext?.mainColor}
                mt={{ base: '20px', md: '0px' }}
                align="center"
                justify="center"
                color="#FFFFFF"
                width="100%"
                height="49px"
                fontFamily={websiteContext.fontFamily}
                fontSize="20px"
                _hover={{
                  textDecoration: 'underline',
                  backgroundColor: websiteContext?.lightColor
                }}>
                Voir le produit
              </Flex>
            </Box>
          </Flex>
        </Flex>
      ) : (
        <Carousel
          isMobile={websiteContext?.isMobile}
          direction={useBreakpointValue({ base: onlyTwoProducts ? 'column' : 'row', md: 'row' })}
          slideWidth={websiteContext?.isMobile ? screenWidth : 330}
          slideHeight={426}
          slidesToShow={Math.min(responsiveSlidesToShow, cleanProducts?.length)}
          slidesToScroll={responsiveSlidesToShow}
          hasScrollX={websiteContext?.isMobile}
          nextArrow={NextArrow}
          prevArrow={PrevArrow}
          displayDots={true}
          slideBelowMd={websiteContext?.isMobile?true:false}
          displayArrow={websiteContext?.isMobile ? false : true}
          dot={Dot}
          gap={0}>
          {cleanProducts?.map((product) => (
            <ProductBlogThumbnail key={`product_${product?.id}`} product={product} />
          ))}
        </Carousel>
      )}
    </Flex>
  )
}

export default memo(BlogProducts)
