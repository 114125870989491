import { Flex, GatsbyImage, Link, Text } from '@stars-ecom/shared-atoms-ui'
import React, { memo } from 'react'

import { DataLayerUtils } from '../context'
import slugify from '../utils/slugify'

const BlogArticleThumbnail = (props) => {
  const { blogArticle, backgroundColor = '#ffffff' } = props

  return (
    <Flex
      w={{ base: '100%', sm: '48%', md: '320px' }}
      h={{ base: 'auto', md: '290px', lg: '350px' }}>
      <Link
        to={`/article/${blogArticle.path || slugify(blogArticle?.title)}`}
        onClick={() =>
          DataLayerUtils.addEvent({
            event: 'qwampEventLab',
            eventCat: 'Blog',
            eventAct: 'Départ article',
            eventLab: `/article/${blogArticle.path || slugify(blogArticle?.title)}`
          })
        }>
        <Flex
          w="100%"
          h="100%"
          direction="column"
          mb="16px"
          overflow="hidden"
          verticalAlign="top"
          cursor="pointer"
          bg={backgroundColor}>
          {blogArticle?.thumbnail_image?.image && (
            <Flex width={{ base: '100%', md: '320px' }} height="170px">
              <GatsbyImage
                image={blogArticle?.thumbnail_image?.image?.childImageSharp?.gatsbyImageData}
                alt={blogArticle?.title}
              />
            </Flex>
          )}
          <Text
            as="h3"
            fontSize={{ base: '4vw', md: '14px', lg: '20px' }}
            lineHeight={{ base: '5vw', md: '17px', lg: '24px' }}
            color="#333"
            textTransform="uppercase"
            p={{ base: '8px', md: '20px' }}
            textAlign="left"
            fontFamily="PT Sans Narrow, PT Sans, Arial, sans-serif"
            fontWeight={700}
            _hover={{ textDecoration: 'underline' }}>
            {blogArticle?.title}
          </Text>
          <Text
            fontSize={{ base: '3vw', md: '10px', lg: '14px' }}
            lineHeight={{ base: '4vw', md: '12px', lg: '17px' }}
            textAlign="left"
            p={{ base: '8px', md: '0 20px 20px' }}
            fontFamily="Arial, sans-serif">
            {blogArticle?.hook}
          </Text>
        </Flex>
      </Link>
    </Flex>
  )
}

export default memo(BlogArticleThumbnail)
