import { Flex, Text } from '@stars-ecom/shared-atoms-ui'
import React, { memo } from 'react'

import BlogArticleThumbnail from './BlogArticleThumbnail'

const BlogCategory = (props) => {
  const { blogCategory } = props
  return (
    <Flex
      backgroundColor="#f6f3f1"
      height="auto"
      w="100%"
      style={{
        textAlign: 'center',
        paddingTop: '0px',
        paddingBottom: '20px',
        transition: 'all .4s'
      }}>
      <Flex
        flexDirection="column"
        w={{ base: 'auto', md: '1000px' }}
        pt="32px"
        pl={{ base: '10px', md: '0' }}
        pr={{ base: '10px', md: '0' }}
        m="0 auto">
        <Text
          as="h1"
          textAlign="center"
          fontSize={{ base: '8vw', md: '27px', lg: '38px' }}
          fontWeight={400}
          style={{ fontFamily: 'Courgette,cursive' }}>
          {blogCategory?.title}
        </Text>
        <Text
          dangerouslySetInnerHTML={{ __html: blogCategory?.description }}
          fontSize={{ base: '3vw', md: '9px', lg: '13px' }}
          lineHeight={{ base: '4vw', md: '11px', lg: '16px' }}
          fontFamily="Arial, sans-serif"
          pt={{ base: '16px', md: '13px', lg: '16px' }}
          pb={{ base: '16px', md: '13px', lg: '16px' }}
          pl="10px"
          pr="10px"
          textAlign="center"></Text>
        <Flex flexDirection="row" flexWrap="wrap" justify="space-evenly" gridGap="10px">
          {blogCategory?.blog_articles?.map((blogArticle, index) => (
            <BlogArticleThumbnail
              blogArticle={blogArticle}
              key={`cat_${blogCategory.id}_art_${index}`}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default memo(BlogCategory)
